export default {
  container: {
    overflow: 'hidden',
    position: 'relative',
    width: '100%',
    paddingTop: '100%'
  },
  hidden: {
    display: 'none'
  },
  videoPreview: {
    top: 0,
    left: 0,
    display: 'block',
    position: 'absolute',
    overflow: 'hidden',
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    transform: undefined
  },
  viewFinder: {
    top: 0,
    left: 0,
    zIndex: 1,
    boxSizing: 'border-box',
    border: '50px solid rgba(0, 0, 0, 0.3)',
    position: 'absolute',
    width: '100%',
    height: '100%'
  }
};