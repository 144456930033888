export const getFacingModePattern = facingMode => facingMode === 'environment' ? /rear|back|environment/gi : /front|user|face/gi;
export const getDeviceId = async facingMode => {
  const devices = await navigator.mediaDevices.enumerateDevices();
  const videoDevices = devices.filter(({
    kind
  }) => kind === 'videoinput');

  if (videoDevices.length < 1) {
    throw new Error('No video input devices found');
  }

  const pattern = getFacingModePattern(facingMode); // Filter out video devices without the pattern

  const filteredDevices = videoDevices.filter(({
    label
  }) => pattern.test(label));
  const [filtered] = filteredDevices;

  if (filtered) {
    return filtered.deviceId;
  }

  const [first, second] = videoDevices;
  return videoDevices.length === 1 || facingMode === 'user' ? first.deviceId : second.deviceId;
};
export const decodeQR = ({
  data,
  width,
  height
}) => {
  // eslint-disable-next-line no-restricted-globals
  const decoded = self.jsQR(data, width, height, {
    inversionAttempts: 'attemptBoth'
  });

  try {
    return JSON.parse(decoded === null || decoded === void 0 ? void 0 : decoded.data);
  } catch (err) {
    return decoded === null || decoded === void 0 ? void 0 : decoded.data;
  }
};
export const log = (msg, color, {
  debug
}) => {
  debug && console.log(`%c${msg}`, `color:${color};font-weight:bold;`);
};
export const isFunction = val => typeof val === 'function';