import { log } from "./utils.js";
export const clearPreview = (preview, {
  debug
}) => {
  if (preview) {
    log(`[QrReader]: Cleaning all properties from video element`, 'yellow', {
      debug
    });
    preview.pause();
    preview.mozSrcObject = null;
    preview.srcObject = null;
    preview.src = '';
  }
};
export const clearStreams = (streams, {
  debug
}) => {
  log(`[QrReader]: Removing all tracks from videoStream`, 'yellow', {
    debug
  });
  const pc = new RTCPeerConnection();
  streams.forEach(stream => {
    if (stream.stop) {
      // Legacy way for older browsers
      stream.stop && stream.stop();
      pc.addStream && pc.addStream(stream);
    } else {
      // New way for modern browsers
      stream.getTracks().forEach(track => {
        track.enabled = !track.enabled;
        track.stop();
        pc.addTrack(track, stream);
      });
    }
  });
};
export const clearFrames = (cancelIds, {
  debug
}) => {
  log(`[QrReader]: Trying to cancel requestAnimationFrame`, 'yellow', {
    debug
  });
  cancelIds.forEach(window.cancelAnimationFrame);
};